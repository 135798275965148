<template>
    <div :class="{ loading }" class="image-pinch">
        <div class="loading">
            <div class="cr-spinner"></div>
        </div>
        <img ref="image" :src="image" @load="resizeImage" />
    </div>
</template>

<script>
    import pinchZoom from '../../../common/pinch-zoom';

    export default {
        name: 'ImagePinch',

        props: {
            image: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                loading: true,
                pinchInitialized: false,

                initialScale: null,
                transform: '',
            };
        },

        mounted() {
            window.addEventListener('orientationchange', this.resizeImage);
            window.addEventListener('resize', this.resizeImage);
        },

        beforeDestroy() {
            window.removeEventListener('orientationchange', this.resizeImage);
            window.removeEventListener('resize', this.resizeImage);
        },

        methods: {
            resizeImage() {
                this.initialScale = null;

                const intervalId = setInterval(() => {
                    const imageHeight = this.$refs.image.offsetHeight;

                    if (imageHeight) {
                        clearInterval(intervalId);

                        this.initialScale =
                            this.$refs.image.parentElement.offsetHeight / imageHeight;

                        this.$refs.image.style.webkitTransform =
                            'translate3d(-50%, -50%, 0) ' +
                            'scale3d(' +
                            this.initialScale +
                            ', ' +
                            this.initialScale +
                            ', 1)';

                        this.$nextTick(() => {
                            this.loading = false;
                            if (!this.pinchInitialized) {
                                this.initPinch(this.initialScale);
                            }
                        });
                    }
                }, 100);
            },

            initPinch(initialScale) {
                const imageElement = this.$refs.image;
                pinchZoom(imageElement, initialScale);
                this.pinchInitialized = true;
                this.$emit('pinch');
            },
        },
    };
</script>
