<template>
    <div id="item-stars">
        <i
            v-for="star in stars"
            v-if="star.isSelected || !showOnlySelected"
            :key="star.id"
            :id="star.id"
            class="icon-cr"
            :class="{
                'icon-star-half-yellow': star.isSelected && star.halfSelected,
                'icon-star-yellow': star.isSelected && !star.halfSelected,
                'icon-star': !star.isSelected,
            }"
        />
    </div>
</template>

<script>
    export default {
        name: 'AvaliacoesShowStars',
        props: {
            starsRating: {
                type: Number,
                required: true,
            },
            showOnlySelected: {
                type: Boolean,
                default: false,
            },
        },
        beforeMount() {
            this.roundedValue = this.round(this.starsRating, 0.5);
            this.stars.map((star, index) => {
                if (index < this.roundedValue) {
                    star.isSelected = true;

                    star.halfSelected = star.id > this.roundedValue;
                }
            });
        },
        data() {
            return {
                stars: [
                    { id: 1, isSelected: false, halfSelected: false },
                    { id: 2, isSelected: false, halfSelected: false },
                    { id: 3, isSelected: false, halfSelected: false },
                    { id: 4, isSelected: false, halfSelected: false },
                    { id: 5, isSelected: false, halfSelected: false },
                ],
                roundedValue: null,
            };
        },

        methods: {
            round(value, step) {
                const inv = 1.0 / step;
                return Math.round(value * inv) / inv;
            },
        },
    };
</script>
