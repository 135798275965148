<template>
    <div
        :style="{ cursor }"
        class="image-zoom-container"
        :class="{ isvideo: isVideo }"
        @click="toggleZoom"
        @mouseleave="showZoomResult = false"
        @mousemove.prevent="moveZoom"
        @touchmove.prevent="moveZoom"
    >
        <div class="image-zoom-placeholder">
            <img
                v-if="!isVideo"
                ref="zoomImage"
                :class="{ busy: !ready }"
                :src="defaultSrc"
                @load="onImageLoaded"
            />
            <iframe
                v-else
                :src="defaultSrc"
                ref="zoomImage"
                :class="{ busy: !ready }"
                @load="onImageLoaded"
                frameborder="0"
                style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%"
                allowfullscreen="allowfullscreen"
                allow="autoplay; fullscreen; picture-in-picture"
            ></iframe>
        </div>
        <div class="slider-bullets-wrapper hidden-md-up">
            <div
                class="mobile-slider-bullet"
                v-for="(image, index) in images"
                :key="image.id"
                :class="index === currentIndex ? 'bg-default-4' : 'bg-white'"
            ></div>
        </div>
        <div
            v-if="ready && showZoomResult"
            :style="zoomResultStyles"
            class="image-zoom-result"
        ></div>
        <div v-show="!ready" class="loading">
            <div class="cr-spinner"></div>
        </div>
    </div>
</template>

<script>
    const ZOOM_RATIO = 2.7;

    export default {
        name: 'ImageZoom',

        props: {
            defaultSrc: String,
            largeSrc: String,
            enabled: Boolean,
            images: Array,
            currentIndex: Number,
            isVideo: Boolean,
        },

        data() {
            return {
                zoomResultStyles: {
                    'background-image': null,
                    'background-size': null,
                    'background-position': null,
                },
                showZoomResult: false,
                ready: false,
            };
        },

        mounted() {
            window.addEventListener('resize', this.setZoomBackgroundSize);
        },

        beforeDestroy() {
            window.removeEventListener('resize', this.setZoomBackgroundSize);
        },

        watch: {
            defaultSrc() {
                this.ready = false;
            },

            showZoomResult(to) {
                this.$emit(this.showZoomResult ? 'zoom-in' : 'zoom-out');

                if (to) {
                    this.setZoomBackgroundImage();
                }
            },
        },

        computed: {
            cursor() {
                if (!this.enabled) return 'auto';
                return this.showZoomResult ? 'zoom-out' : 'zoom-in';
            },
        },

        methods: {
            onImageLoaded() {
                this.setZoomBackgroundSize();
                this.ready = true;
            },

            setZoomBackgroundImage() {
                this.ready = false;
                const img = new Image();
                img.onload = () => {
                    img.remove();
                    this.zoomResultStyles['background-image'] = `url('${this.largeSrc}')`;
                    this.$nextTick(() => (this.ready = true));
                };
                img.src = this.largeSrc;
            },

            setZoomBackgroundSize() {
                this.zoomResultStyles['background-size'] =
                    this.$refs.zoomImage.width * ZOOM_RATIO +
                    'px ' +
                    this.$refs.zoomImage.height * ZOOM_RATIO +
                    'px';
            },

            toggleZoom() {
                if (this.enabled) {
                    this.showZoomResult = !this.showZoomResult;
                }
            },

            moveZoom(event) {
                const maxX =
                    this.$refs.zoomImage.width - this.$refs.zoomImage.offsetWidth / ZOOM_RATIO;
                const maxY =
                    this.$refs.zoomImage.height - this.$refs.zoomImage.offsetHeight / ZOOM_RATIO;

                const point = this.getRelativeCursorPoint(event);

                let x = point.x - this.$refs.zoomImage.offsetWidth / (2 * ZOOM_RATIO);
                let y = point.y - this.$refs.zoomImage.offsetHeight / (2 * ZOOM_RATIO);

                x = Math.max(0, Math.min(maxX, x));
                y = Math.max(0, Math.min(maxY, y));

                this.zoomResultStyles['background-position'] =
                    '-' + x * ZOOM_RATIO + 'px -' + y * ZOOM_RATIO + 'px';
            },

            getRelativeCursorPoint(event) {
                const imgRect = this.$refs.zoomImage.getBoundingClientRect();

                let x = event.pageX - imgRect.left;
                let y = event.pageY - imgRect.top;

                x = x - window.pageXOffset;
                y = y - window.pageYOffset;

                return { x, y };
            },
        },
    };
</script>
