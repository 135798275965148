import './common/base';
import Vue from './vue/common/vue-common';
import ProductPage from './vue/components/product/ProductPage';
import RenderNotifier from './vue/components/common/RenderNotifier';
import product from './vue/i18n/product';
import shop from './vue/i18n/shop';
import marketing from './vue/i18n/marketing';
import general from './vue/i18n/general';
import locale from './vue/i18n/locale';
import gallery from './vue/i18n/gallery';
import VueMask from 'v-mask';

Vue.use(VueMask);

cr$.ready(function () {
    new Vue({
        el: '#product-vue',
        i18n: locale(general(), shop(), product(), gallery(), marketing()),
        components: {
            'render-notifier': RenderNotifier,
            'product-page': ProductPage,
        },
    });
});
